import React from 'react'
import UnderBar from '../../common/UnderBar/UnderBar'
import styles from './Home.module.css'
import img_kakao from '../../img/kakaotalk.png'
import img_kakao_cut from '../../img/kakaotalk_cut.png'

const Home = () => {


    return (
        <div className={styles.wrap}>
            <p>페이지 제작 중입니다.</p>

            <div className={styles.kakao_wrap} onClick={() => window.open('http://pf.kakao.com/_QYxjyG/chat', '_blank', 'noopener, noreferrer')} >
                <img className={styles.img_kakao} src={img_kakao_cut} alt='' />
                <p>상담하기</p>
            </div>


            {/* <UnderBar /> */}
        </div>
    )
}

export default Home