import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import styles from './AppLayout.module.css'

const AppLayout = () => {

   const navigate = useNavigate()
   return (
      <div>
         <div className={styles.wrap}>
            <div className={styles.left_area} onClick={() => navigate('/')}>
               <img src={`${process.env.PUBLIC_URL}/logo.png`} alt='' />
               <h1>Raul Soft</h1>
            </div>
            <div className={styles.right_area}>
               <p>content1</p>
               <p>content2</p>
               <p>content3</p>
            </div>


            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt='' />
            <h1>Raul Soft</h1>
         </div>
         <Outlet />
      </div>
   )
}

export default AppLayout